import React  from "react";
import {Text,Heading,Box, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import {motion} from 'framer-motion'
import MySeo from '../components/seo' 

export default function Wolff(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >   
        <MySeo title="Bertha Wolff (1873–1942) und Jenny Wolff (1873–1942)" />
        <Box  mt="60px"><Link to="/bacharach"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid mt="60px" gap={4} columns={[1,1,2]}>
        <Box>
            <Heading fontSize="lg" py="8">Bertha Wolff (1873–1942) und Jenny Wolff (1873–1942)</Heading>
            
            <Text variant="solid">
            Bertha und Jenny Wolff wurden beide am 24. November 1873 in Oberheimbach als Töchter von Carl Wolff und Pauline Wolff, geb. Liebmann geboren. Sie blieben zeitlebens ledig und wohnten in der Blücherstraße 40. Nachdem der kinderlose Bruder ihres Vaters gestorben war, erbten sie auch das Haus in der Blücherstraße 38. Über ihr Leben ist nur wenig bekannt. Es ist nicht nachvollziehbar, wie sie ihren Lebensunterhalt bestritten.
            </Text>
           
            <Text variant="solid">
            Wie bedacht die Bacharacher Jüdinnen und Juden um das Verhältnis zur christlichen Bevölkerung waren, zeigt ein Vorfall, an dem eine der „Wolffe-Schwestern“, wie sie genannt wurden, beteiligt gewesen sein soll. So soll sie mit ihrem Fahrrad einen leichten Unfall in der Blücherstraße verursacht haben, bei dem eine Passantin leicht verletzt wurde. Aus Angst, man könne ihr dieses Ereignis negativ auslegen, brachte sie ihr am nächsten Tag Geschenke.
            </Text>
            <Text variant="solid">
            Im November 1941 mussten die Schwestern Bertha und Jenny Wolff vermutlich in ein sogenanntes Judenhaus, eine Sammelstelle vor der endgültigen Deportation in den Osten, in der Rosenstraße 14 zwangsumsiedeln. Ihr Hausbesitz hingegen wurde eingezogen, und fast ein Jahr später wurde die Reichsfinanzverwaltung Oberpräsident Köln als Eigentümer eingetragen. Als Verwalter wurde Ferdinand Jakob Wasum eingesetzt, der ebenfalls das Haus der Geschwister Keller in der Langstraße 43 verwaltete. Die Mieteinnahmen nutzte er, um Steuern und anfallende Reparaturen zu bezahlen, die Überschüsse wurden auf ein Sperrkonto bei der Kreissparkasse in St. Goar eingezahlt. </Text>
            
            <Text variant="solid">Zum 27. Juli 1942 wurden die Geschwister Wolff in Bacharach abgemeldet, ihre Namen befanden sich auf der Transportliste eines Zuges, der sie über Koblenz nach Köln bringen sollte. Eine Zeitzeugin berichtete, dass sie als elfjähriges Mädchen in einem Haus an der Ecke Rosenstraße lebte. Sie habe hören können, wie sich die beiden Frauen mit lautem Schreien und Weinen gegen ihre Deportation wehrten. An diesem Tag ging der Transport von Köln in das Ghetto Theresienstadt in der besetzten Tschechoslowakei ab. Jenny Wolff starb dort am 13. August 1942. Ihre Schwester Bertha verstarb nur zwei Wochen später. Ihre Todesfallanzeige hat sich erhalten. Daraus geht hervor, dass sie am 27. August 1942 um 15.10 Uhr in der Krankenstube des Ghettos Theresienstadt den Tod fand. Der behandelnde Arzt Dr. Benjamin Roth dokumentierte, dass sie unter „Darmcatarrh“ und Altersschwäche litt und an Herzschwäche gestorben sei. Während des Hochsommers 1942 hatte eine Enteritis-Epidemie eingesetzt, die sich in einer Entzündung des Dünndarms äußert. Ausgelöst durch unzureichende, verdorbene Nahrung und die mangelhaften sanitären Verhältnisse , griff diese rasch um sich und forderte zahlreiche Opfer. Dies und die Erlebnisse der letzten Wochen dürften wohl maßgeblich zum plötzlichen Ableben der 68-jährigen Bertha Wolff beigetragen haben.</Text>
            <Text variant="solid">Das Geld auf dem Sparbuch der beiden Schwestern wurde nach ihrem Tod von der Kreissparkasse St. Goar an das Finanzamt überwiesen. 1946 meldete die einzig lebende Verwandte, eine Nichte, die in England lebte, ihre Rechte auf Haus und Vermögen an, da beides seit 1945 unter Kontrolle der Militärregierung unterstand. Ihr wurde das Geld auf dem Sperrkonto zugesprochen. 1959 wurde das Haus in der Blücherstraße an das Ehepaar Erich und Inge Schmidt verkauft. Gustav und Minna Ketzer kauften das Haus Nr. 38. Für Jenny und Bertha Wolff befindet sich ein Stolperstein in die Vitrine des Rathaussaales, da die heutigen Eigentümer der Häuser nicht mit einer Verlegung vor Ort einverstanden waren.</Text>
            </Box>
            <Box  my="9">
            <Box pt={["1","1","16"]}>
                    <StaticImage src="../images/bacharach/04.jpg" alt=""/>
                    <Text variant="Bild">Bertha Wolff  und Jenny Wolff  </Text>
                </Box>
               
                
            </Box>
            </SimpleGrid>
            </motion.div>
            
    )
}